import React from "react"
import styled from "styled-components"
import Seo from "src/components/SEO"
import SectionFullBleed from "src/components/Section/SectionFullBleed"

const NotFoundPage = () => {
  return (
    <Section>
      <Seo title="Page not found" />
      <SectionFullBleed
        primaryTitle="Error 404"
        title="Page not found."
        image="/images/hero/hen.jpg"
      />
    </Section>
  )
}

const Section = styled.div``

export default NotFoundPage
